<template>
  <div class="auth-login__grid">
    <div
      ref="formRef"
      class="auth-login is-left"
      :class="{ 'is-checkout': data.login_checkout && data.login_checkout.length === 1 }"
    >
      <FormController
        v-if="data.form && data.form.length === 1"
        :data="data.form[0]"
        :is-loading="isLoading"
        :class="{ 'is-checkout': data.login_checkout && data.login_checkout.length === 1 }"
        @on-submit="formAction"
      >
        <div class="auth-login__button-wrapper">
          <AtomButton
            class="auth-login__button"
            button-type="submit"
            :is-disabled="isLoading"
            :text="data.form[0].button_text"
          />
          <AtomCta
            v-if="data.signup_link && data.login_checkout.length === 0"
            :data="data.signup_link"
            :link="data.signup_link.cached_url"
            :text="useTranslation('general', 'sign_up')"
            class="auth-login__button-signup"
          />
        </div>
      </FormController>
    </div>

    <AtomDivider
      v-if="data.login_checkout && data.login_checkout.length === 1"
      class="auth-login__divider"
    />

    <AuthLoginCheckout
      v-if="data.login_checkout && data.login_checkout.length === 1"
      :data="data.login_checkout[0]"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
});

const { login } = useStrapiAuth();
const formRef = ref(null);

const {
    isLoading,
    send,
} = useFormAction(formRef);

const redirect = computed(() => props.data?.redirect_link?.cached_url);
const cartStore = useCartStore();

const { sendEvent } = useGTM();

const formAction = (values) => {
    const { getClientID } = useGTM();
    const clientId = getClientID();

    sendEvent({
        event: 'login',
    });

    send(
        async () => {
            const submitValues = { ...values };
            submitValues.clientId = clientId;
            delete submitValues.statusData;
            await login(submitValues);

            cartStore.initializeCart();
            const bookmarkStore = useBookmarkStore();
            bookmarkStore.syncBookmarks();
        },
        values,
        removeTrailingSlash(`/${redirect.value}`),
    );
};
</script>

<style lang="scss" scoped>
.auth-login__grid {
    @include grid-columns();
    @include grid-layout();

    position: relative;
}

.auth-login {
    @include default-content-columns(true);

    &.is-checkout {
        @include grid-columns(3, 6);

        @include tablet {
            @include grid-columns(1, 5);
        }

        @include tablet-portrait {
            @include grid-columns(1, 6);
        }
    }

    .auth-login__button-wrapper {
        display: flex;
    }

    .auth-login__button {
        @include fluid('margin-right', 10px, 32px);
    }
}

.auth-login__divider {
    &.atom-divider {
        position: absolute;
        top: -33px;
        left: 50%;
        width: 2px;
        height: 407px;
        background: $C_GREY_LINE;
        transform: translate(-50%, 0);

        @include mobile {
            @include grid-columns(1, 1);
            position: relative;
            top: 0;
            width: 100%;
            height: 2px;
            margin-bottom: 28px;
        }
    }
}
</style>
